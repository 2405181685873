<template>
  <v-app >
    <v-main>
      <v-container fill-height class='pa-0' style="max-width:600px;" >
        <router-view :loadng.sync='loading' />
      </v-container>
    </v-main>
    <v-progress-linear indeterminate v-if='loading' />
  </v-app>
</template>
<script>
export default {
  name: 'App',
  data () {
    return {
      loading: false
    }
  },
  mounted () {
    // set to preview
    this.$cookies.set("nf_ab","next","30d", null, "appointmentguru.co");
  }
}
</script>
