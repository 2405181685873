<template>
<v-card flat tile height="100%" width='100%' class='mt-sm-15' >
  <v-img max-height=100 class="mt-0 mt-sm-10" contain
    lazy-src="https://ik.imagekit.io/appointmentguru/brand/Appointment_guru_logo_white_background.png?tr=h-30"
    src="https://ik.imagekit.io/appointmentguru/brand/Appointment_guru_logo_white_background.png?tr=h-150" />

  <!-- <v-card-text class='justify-center text-center' > -->
    <v-window v-model="step" vertical class='mb-15' >

      <v-window-item >
        <sign-up @done="step=1" />
      </v-window-item >

      <v-window-item >
        <personal @done="step=2" @progress='setProgress' />
      </v-window-item >

      <v-window-item >
        <business @done='step=3' @progress='setProgress' />
      </v-window-item >

      <v-window-item >
        <v-card class='ma-1' >
          <v-card-title class='overline px-1 pl-2' style='line-height:1rem !important;' >
            <template v-if='$vuetify.breakpoint.smAndUp' >
            You're set up! <br />
            Let's get started!
            </template>
            <div v-else class='pb-3 title font-weight-light text-center' style='width:100%;'  ><center>
              You're set up! Let's get started!
              </center>
            </div>

            <v-spacer />
            <v-btn x-large :href="appLink" :block='$vuetify.breakpoint.xs'
              color="primary" >
              Launch AppointmentGuru
              <v-icon class='ml-1' color='amber' >mdi-rocket-launch</v-icon>
            </v-btn>
          </v-card-title>
          <space-card v-model='space' />
        </v-card>
      </v-window-item >

    </v-window>

    <v-stepper
      v-model="stepperStep"
      class='hidden-xs-only grey lighten-4'
      style='position:fixed;top:0px;left:0px;right:0px;' >
      <v-stepper-header>
        <v-stepper-step
          :complete="step > 0" step="1" >
          Sign up
        </v-stepper-step>
        <v-stepper-step
          :complete="step > 1" step="2" >
          Professional details
        </v-stepper-step>
        <v-stepper-step
          :complete="step > 2" step="3" >
          Business details
        </v-stepper-step>
        <v-stepper-step
          :complete="step > 3" step="4" >
          Done!
        </v-stepper-step>
      </v-stepper-header>
      <v-progress-linear v-if='step > 0 && step < 3' :value='progress' />
    </v-stepper>

  <!-- </v-card-text> -->
  <!-- <v-bottom-navigation absolute horizontal >
      <v-btn @click='step--' >
        <span>Back</span>
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-spacer/>
      <v-btn @click='step++' >
        <v-icon class='ml-4'>mdi-chevron-right</v-icon>
        <span>Next</span>
      </v-btn>
  </v-bottom-navigation> -->

</v-card>
</template>
<script>
import SignUp from './SignUp.vue'
import Personal from './Personal.vue'
import Business from './Space/Index.vue'
import SpaceCard from '@/components/SpaceCard.vue'

export default {
  name: 'GetStarted',
  components: {
    SignUp,
    Personal,
    Business,
    SpaceCard
  },
  data () {
    return {
      step: 0,
      progress: 0,
      getStartedDialog: false,
      appLink: process.env.VUE_APP_AG_APP_LINK
    }
  },
  watch: {
    step () {
      this.progress = 0
    }
  },
  methods: {
    setLoading (isLoading) {
      this.$emit('update:loading', isLoading)
    },
    setProgress (percent) {
      this.progress = percent
    }
  },
  computed: {
    stepperStep () { return this.step + 1 }
  }
}
</script>
