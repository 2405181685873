<template>
<v-row row>
  <v-col>
    <v-checkbox dense hide-details
      v-for='(location, index) in spaceLocations'
      v-model="selected"
      :value='spaceLocations[index].id'
      :key='location.id'
      :label='location.name'
      v-bind="attrs" />
  </v-col>
</v-row>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LocationPicker',
  props: {
    value: { required: false, type: Array },
    attrs: { type: Object, default: () => { return {} } }
  },
  data () {
    return {
      selected: []
    }
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.selected = this.value
      }
    },
    selectedCount () {
      this.$emit('input', this.selected)
    }
  },
  computed: {
    ...mapGetters(['spaceLocations']),
    selectedCount () { return this.selected.length }
  }
}
</script>
