<template>
<v-card tile >
  <v-img :height='150' :src='backgroundPicture' >
  </v-img>

  <v-card-title class='overline py-0 grey lighten-4' >
    <v-spacer/>
    {{value.title}}
  </v-card-title>
  <v-divider/>

  <v-card-text v-if='value && value.settings' class='text-right'>
    <span v-if='value.settings.practice_number' >Practice number: {{value.settings.practice_number}}</span>
    <v-img :src='logoPicture' max-width="150" max-height="150" absolute
    class='ml-4 elevation-10' style='margin-top:-120px;' />
  </v-card-text>
  <v-card-text v-if='value && value.settings' class='pt-0 mt-0' >
    <v-subheader class="overline pl-0">Invoice details</v-subheader>
    <v-divider/>
    <v-row row wrap >
      <!-- {{value.settings}} -->
      <v-col cols=6 v-if='value.settings.billing_address' >
        <v-subheader class='overline pl-0 caption'>billing address</v-subheader>
        {{value.settings.billing_address}}</v-col>
      <v-col cols=6
        v-if='value.settings.banking_details' >
        <v-subheader class='overline pl-0 caption'>banking details</v-subheader>
        {{value.settings.banking_details}}</v-col>
    </v-row>
    <template v-if='spaceLocations && spaceLocations.length > 0' >
      <v-subheader class="overline pl-0">Locations</v-subheader>
      <v-divider/>
      <template v-for='location in spaceLocations' >
        <location-card :value='location' :key='location.id' class='mt-2' />
      </template>
    </template>

    <template v-if='spaceProducts && spaceProducts.length > 0' >
      <v-subheader class="overline pl-0">Appointment Types</v-subheader>
      <v-divider/>
      <v-list dense>
        <template v-for='product in spaceProducts' >
          <v-list-item :key='product.id' >
            <v-list-item-content>
              <v-list-item-title>{{product.title}}</v-list-item-title>
              <v-list-item-subtitle>{{product.duration}} mins @ {{ formatcurrency(null, product.price) }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </template>
  </v-card-text>

  <slot name='card-actions' >
  <v-card-actions >
  </v-card-actions>
  </slot>

  <v-divider/>
  <v-footer class='caption pa-1' >
    You can further configure this space (and much more) from:
    <code>settings -> spaces</code> in the app.
  </v-footer>

  <!-- <pre v-text='space' />
  <pre v-text='spaceSettings' /> -->
</v-card>
</template>
<script>
import LocationCard from '@/components/cards/LocationCard.vue'
import {mapGetters} from 'vuex'
export default {
  name: 'SpaceCard',
  components: { LocationCard },
  props: {
    value: { type: Object, required: true }
  },
  computed: {
    ...mapGetters(['spaceLocations', 'spaceProducts']),
    backgroundPicture () {
      let bg = this.getObjectPath(this.value, 'settings.background_picture')
      if (!bg) {
        bg = 'https://images.unsplash.com/photo-1415025148099-17fe74102b28?ixlib=rb-1.2.1&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjExOTkxfQ'
      }
      return bg
    },
    logoPicture () {
      // return 'https://media-appointmentguru.s3.amazonaws.com/practitioner/images/space-271/logo.png'
      return this.getObjectPath(this.value, 'settings.logo_picture')
    }
  }
}
</script>
