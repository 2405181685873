<template>
  <div class="gmaps" :class="{loading: loading, failed: failed}" >
    <slot>
      <v-text-field
        @focus='start'
        prepend-icon="place"
        hint="Start typing an address and we'll search Google for places"
        solo persistent-hint
        v-model='addressString'
        class="input-group--focused"
        :clearable='true' type='text' :id='id' >
      </v-text-field>
    </slot>
  </div>
</template>
<script>
/* global google */
export default {
  name: 'AddressPicker',
  data () {
    return {
      inited: false,
      location: {},
      addressString: null,
      loading: true,
      failed: false
    }
  },
  props: {
    apiKey: { type: String, required: false }, // true
    id: {
      type: String,
      default: () => { return Math.random().toString().split('.')[1] }
    },
    value: { type: Object, default: () => { return {} } }
  },
  mounted () {
    this.createGoogleMaps().then(this.initAutoComplete, this.googleMapsFailedToLoad)
  },
  methods: {
    start () {
      if (!this.inited) { this.initAutoComplete() }
    },
    initAutoComplete () {
      let el = document.getElementById(this.id)
      this.autocomplete = new google.maps.places.Autocomplete(el)
      this.autocomplete.addListener('place_changed', this.addressChanged)
      this.geolocate()
      this.loading = false
      this.$emit('autocompleteinited', this.autocomplete)
      this.inited = true
      console.log('loaded')
    },
    googleMapsFailedToLoad (result) {
      console.log(result)
      this.failed = true
      this.$emit('gmapserror', result)
      console.warn('Could not load google maps')
    },
    createGoogleMaps () {
      return new Promise((resolve, reject) => {
        let gmapsId = 'google-maps-api'
        if (!document.getElementById(gmapsId)) {
          let gmap = document.createElement('script')
          gmap.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places`
          gmap.type = 'text/javascript'
          gmap.id = gmapsId
          gmap.onload = resolve
          gmap.onerror = reject
          document.body.appendChild(gmap)
        }
      })
    },
    geolocate () {
      let vm = this
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          var geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }
          var circle = new google.maps.Circle({
            center: geolocation,
            radius: position.coords.accuracy
          })
          if (vm.autocomplete) {
            vm.autocomplete.setBounds(circle.getBounds())
          }
          vm.$emit('geolocationset', circle.getBounds())
        })
      }
    },
    addressChanged () {
      let location = this.autocomplete.getPlace()
      this.$emit('input', location)
    }
  }
}
</script>
