<template>
<v-card-actions >
  <v-btn text
    v-if='withBack'
    @click='$emit("back")'  >
    Back</v-btn>

  <v-spacer/>

  <v-btn text
    v-if='withSkip'
    @click='$emit("skip")' >Skip this step</v-btn>

  <v-btn v-if='withNext'
    :disabled='nextDisabled'
    :lloading='nextLoading'
    color='primary'
    @click='$emit("next")' >Next</v-btn>

</v-card-actions>
</template>
<script>
export default {
  name: 'CardACtions',
  props: {
    withBack: { type: Boolean, default: true },
    withNext: { type: Boolean, default: true },
    withSkip: { type: Boolean, default: true },
    nextDisabled: { type: Boolean, default: false },
    nextLoading: { type: Boolean, default: false },
  }
}
</script>
