<template>
<v-card tile class='my-1' >
  <v-img :src='preview' >
    <v-list class='ma-3 elevation-5' style='opacity:0.9;' >
      <v-list-item >
        <v-list-item-content>
          <v-list-item-title v-text="location.name" />
          <v-list-item-subtitle v-text="location.address" />
          <v-list-item-subtitle v-text="latlng" />
        </v-list-item-content>
        <v-list-item-action >
          <v-btn icon ><v-icon>mdi-open-in-new</v-icon></v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-img>
  <!-- <v-card-text>
    {{value}}
  </v-card-text> -->
</v-card>
</template>
<script>
export default {
  name: 'LocationCard',
  props: {
    value: { type: Object }
  },
  computed: {
    location () { return this.value },
    latlng () {
      if (this.location.latitude && this.location.longitude) {
        let lat = parseFloat(this.location.latitude).toFixed(5)
        let lng = parseFloat(this.location.longitude).toFixed(5)
        return `${lat},${lng}`
      }
      return null
    },
    gmapsURL () {
      let base = 'https://www.google.com/maps/search/?api=1'
      let query = this.location.address
      if (this.latlng) {
        query = `query=${this.latlng}`
      }
      if (this.location.place_id) {
        query += '&query_place_id=' + this.location.place_id
      }
      return `${base}&${query}`
    },
    preview () {
      if (this.location) {
        if (this.location.is_virtual === true) {
          return 'https://images.unsplash.com/photo-1480694313141-fce5e697ee25?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80'
        }
        if (this.location && this.location.latitude && this.location.longitude) {
          let lat = this.location.latitude
          let lng = this.location.longitude
          return `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=10&size=280x120&scale=4&markers=size:tiny|color:blue|${lat},${lng}&key=AIzaSyDBLqBJhup-u2afKcqqonWzGC-vv_7YpnY`
        }
      }
      return null
    }
  }
}
</script>
