<template>

  <v-window v-model="step" vertical >
    <!-- <v-text-field v-model='step' type='number' /> -->
    <!-- T:{{otptoken}}|{{client_id}}|{{client_secret}} -->
    <!-- <v-btn @click='step=1' /> -->
    <v-window-item >
      <v-card flat :disabled='this.reqs.otp.loading'>
        <request-status :request='reqs.otp' />

        <v-card-text class='text-center' >
          <blockquote class='blockquote pl-1' >
          We can send a reset code to your registered email or phone number
          </blockquote>
          <v-btn block outlined small
            class='ma-2'
            color='primary'
            :disabled='!email'
            @click='requestOTP("email")' >Send to {{ idInfo.email }}</v-btn>
          <!-- <v-btn block outlined small
            class='ma-2' color='primary'
            @click='requestOTP("sms")' >Send to {{ idInfo.phone_number }}</v-btn> -->
        </v-card-text>
        <v-card-actions >
          <v-spacer/>
          <v-btn @click='step=1' text >I've got a code</v-btn>
        </v-card-actions>
      </v-card>
    </v-window-item >

    <v-window-item >
      <v-card :disabled='reqs.login.loading' >
        <request-status :request='reqs.login' >
          <template text slot='message-401' >
            <span v-if='reqs.login.errors && reqs.login.errors.message' v-text='reqs.login.errors.message' />
            <span v-else-if='reqs.login.errors && reqs.login.errors.detail' v-text='reqs.login.errors.detail' />
            <span v-else >Unable to reset password</span>
          </template>
        </request-status>

        <v-card-text>
          <p>Please enter the code below</p>
          <v-text-field solo
            v-model='otp'
            label='Code'
            hint='Enter the code that we sent to your preferred contact' />
        </v-card-text>

        <v-card-actions >
          <v-btn @click='step--' text color='primary' >Resend code</v-btn>
          <v-spacer />
          <v-btn
            :loading='reqs.login.loading'
            @click='login' color='primary' >Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-window-item>

    <v-window-item >
      <v-card>
        <request-status :request='reqs.password' />
        <v-card-title class='subtitle-1 overline' >
          Set your new password
        </v-card-title>
        <v-card-text>
          <v-text-field solo
            v-model= "password"
            label='Set your new password'
            type='password' />
        </v-card-text>
        <v-card-actions >
          <v-spacer />
          <v-btn
            :loading='reqs.password.loading'
            @click='setPassword' color='primary' >Set password</v-btn>
        </v-card-actions>
      </v-card>
    </v-window-item>
    <v-alert dense text dismissible tile
      v-if="showExtraInfo"
      type='info' class='mb-0' border="top" >
      Having trouble logging in? Contact support@appointmentguru.co and we'll help you out!
    </v-alert>
  </v-window>

</template>
<script>
import {mapGetters} from 'vuex'

export default {
  name: 'Recover',
  props: {
    email: { type: String },
    withOtp: { type: Boolean }
  },
  data () {
    return {
      step: 0,
      otp: null,
      password: null,
      showExtraInfo: false,
      extraHelpHintTimeout: 60000,
      methodUsed: 'email',
      reqs: {
        otp: { resource: 'ag.auth', extra: 'forgot-password', method: 'post' },
        login: { resource: 'auth.login', method: 'post' },
        password: { resource: 'ag.auth', extra: 'set-password', method: 'post' }
      },
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET
    }
  },
  watch: {
    otpStatus () {
      if (this.otpStatus === 200) { this.step = 1 }
    },
    otptoken () {
      if (this.otptoken) {
        this.$store.dispatch('auth/logUserIn', this.otptoken)
        this.$emit('loggedin', this.otptoken)
        this.step = 2
      }
    },
    passwordStatus () {
      if (this.passwordStatus === 200) {
        this.$emit('done')
      }
    },
    otp () {
      if (this.otp && this.otp.length === 4) {
        this.login()
      }
    },
    withOtp: {
      immediate: true,
      handler () {
        if (this.withOtp === true) {
          this.step = 1
        }
      }
    },
    step () {
      if (this.step === 1) {
        this.triggerHelper()
      }
    }
  },
  computed: {
    ...mapGetters(['idInfo']),
    otptoken () { return this.getObjectPath(this.reqs, 'login.result.access_token') },
    otpStatus () { return this.reqs.otp.status },
    loginStatus () { return this.reqs.login.status },
    passwordStatus () { return this.reqs.password.status }
  },
  methods: {
    requestOTP (method) {
      this.reqs.otp.data = {
        method,
        email: this.email
      }
      this.$request('save', this.reqs.otp)
      this.methodUsed = method
    },
    login () {
      this.reqs.login.data = {
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
        otp: this.otp,
        email: this.email
        // phone_number: "+27832566533"
      }
      this.$request('save', this.reqs.login)
    },
    setPassword () {
      this.reqs.password.data = {
        password: this.password,
        promote_to_practitioner: true
      }
      this.$request('save', this.reqs.password)
    },
    triggerHelper () {
      window.setTimeout(() => { this.showExtraInfo = true }, this.extraHelpHintTimeout)
    }
  }
}
</script>
