<template>
<v-card tile class="elevation-10 ma-sm-2" >
  <v-card-title class='subtitle-1 overline justify-center py-0 grey darken-4 white--text pr-0' >
    Let's get your business setup
    <!-- <v-btn @click='get' /> -->
    <v-spacer/>
    <!-- <v-btn small flat dark @click='step++' >
      Next
      <v-icon small >mdi-arrow-right</v-icon>
    </v-btn> -->
  </v-card-title>
    <request-status :request='req.space' />
    <request-status :request='req.settings' />
    <v-window v-model="step" vertical >
      <v-window-item >

          <v-text-field filled autofocus persistent-hint
            v-model='spaceTitle'
            label='What is the name of your business?'
            :hint='businessNameHint'
            append-icon='mdi-arrow-right'
            @keypress.enter="savespace(true)"
            @click:append='savespace(true)' />

          <card-actions
            :with-back='false'
            :with-skip='false'
            :next-loading='req.space.loading'
            @next='savespace(true)' />
          <!-- <v-card-actions >
            <v-spacer />
            <v-btn
              :loading='req.space.loading'
              color='primary'
              @click='savespace(true)' >Next</v-btn>
          </v-card-actions> -->

      </v-window-item >

      <v-window-item v-if="isMedical" >
        <!-- {{spaceId}} | {{spaceSettingsId}} | {{settingsPracticeNumber}} -->
        <v-text-field filled autofocus
          v-model='settingsPracticeNumber'
          label='Do you have a practice number?'
          append-icon='mdi-arrow-right'
          @keypress.enter="saveSetting('practice_number', settingsPracticeNumber, true)"
          @click:append="saveSetting('practice_number', settingsPracticeNumber, true)" />

        <card-actions
            :next-loading='req.space.loading'
            @back='step--'
            @skip='step++'
            @next="saveSetting('practice_number', settingsPracticeNumber, true)" />

      </v-window-item>

      <v-window-item >
        <!-- {{spaceId}} | {{spaceSettingsId}} | {{settingsPracticeNumber}} -->
        <v-subheader class='overline' >Your billing address</v-subheader>
        <v-textarea filled autofocus persistent-hint
          v-model='settingsBillingAddress'
          label='Your billing address'
          hint="This will be added to the invoices that you generate for your clients"
          append-icon='mdi-arrow-right'
          @click:append="saveSetting('billing_address', settingsBillingAddress, true)" />

        <card-actions
            :next-loading='req.space.loading'
            @back='step--'
            @skip='step++'
            @next="saveSetting('billing_address', settingsBillingAddress, true)" />

      </v-window-item>

      <v-window-item >
        <!-- {{spaceId}} | {{spaceSettingsId}} | {{settingsPracticeNumber}} -->
        <v-subheader class='overline' >Your banking details</v-subheader>
        <v-textarea filled autofocus persistent-hint
          v-model='settingsBankingDetails'
          label='Your banking details'
          hint="These will be added to the invoices that you generate for your clients"
          append-icon='mdi-arrow-right'
          @click:append="saveSetting('banking_details', settingsBankingDetails, true)" />

        <card-actions
            :next-loading='req.space.loading'
            @back='step--'
            @skip='step++'
            @next="saveSetting('banking_details', settingsBankingDetails, true)" />

      </v-window-item>

      <v-window-item >
        <v-card :elevation='10' >
          <v-card-title class='subtitle-1 overline justify-center' >
            Add a logo
          </v-card-title>

          <uploader flat avatarTile
            v-model='spaceSettings.logo_picture'
            filename='logo_picture'
            :upload-path = "`/api/v3/spaces/${spaceId}/settings/${spaceSettingsId}/`"
            @input='step++'
            @uploadfailure='pictureUploadError=true' />


          <card-actions :with-next='false'
            @back='step--'
            @skip='step++' />

          <!-- <v-card-actions >
            <v-btn @click='step--' text >Back</v-btn>
            <v-spacer/>
            <v-btn @click="step++" small text >Skip this step</v-btn>
            <v-btn color='primary' >Next</v-btn>
          </v-card-actions> -->
        </v-card>
      </v-window-item>

      <v-window-item >
        <locations @back='step--' @next='step++' />
      </v-window-item>

      <v-window-item >
        <appointment-types v-model='products' @back='step--' @next='$emit("done")' />
      </v-window-item>

      <!-- <v-window-item >
        <space-card v-model='space' >
          <v-card-actions slot='card-actions' class='mt-10' >
            <v-spacer />
            <v-btn @click='$emit("done")' large color="primary" >Next</v-btn>
          </v-card-actions>
        </space-card>

      </v-window-item> -->

    </v-window>

</v-card>
</template>
<script>
import AppointmentTypes from './AppointmentTypes.vue'
import Locations from './Locations.vue'
import CardActions from './CardActions.vue'
import Uploader from '@/components/ui/Uploader.vue'
// import SpaceCard from '@/components/SpaceCard.vue'
import {mapGetters} from 'vuex'

export default {
  name: 'Space',
  components: { AppointmentTypes, Uploader, Locations, CardActions },
  props: {
    value: { type: Object },
    user: { type: Object }
  },
  data () {
    return {
      step: 0,
      advance: false,
      pictureUploadError: false,
      req: {
        space: {
          resource: 'ag.spaces'
        },
        settings: {
          resource: 'ag.spaces',
          extra: 'settings'
        },
        addresses: {
          resource: 'ag.spaces',
          extra: 'addresses'
        }
      },
      locations: [],
      products: []
    }
  },
  mounted () {
    this.get()
    this.$emit('progress', 0)
  },
  methods: {
    get () {
      this.$request('get', this.req.space)
    },
    savespace (advance) {
      if (this.spaceId) {
        this.req.space.resourceId = this.spaceId
        this.req.space.data = {
          title: this.spaceTitle
        }
        this.$request('save', this.req.space)

        this.advance = false
        if (advance === true) { this.advance = true }
      }
    },
    saveSetting (key, value, advance) {
      this.req.settings.data = {}
      this.req.settings.data[key] = value
      this.req.settings.resourceId = this.spaceId
      this.req.settings.extra = 'settings/' + this.spaceSettingsId
      this.$request('save', this.req.settings)

      this.advance = false
      if (advance === true) { this.advance = true }
    }
  },
  watch: {
    spaceReqStatus () {
      if (this.spaceReqStatus === 200) {
        let space = null
        if (this.req.space.resourceId) {
          space = this.req.space.result
        } else {
          let res = this.getObjectPath(this.req.space, 'result.results', [])
          if (res.length > 0) {
            space = this.req.space.result.results[0]
          }
        }
        if (space) {
          this.$store.commit('setSpace', space)
          if (space.settings) {
            this.$store.commit('setSpaceSettings', space.settings)
          }
        }
        if (this.advance === true) { this.step++ }
      }
    },
    settingsReqStatus () {
      if (this.settingsReqStatus === 200) {
        let settings = this.req.space.result
        this.$store.commit('setSpaceSettings', settings)
        if (this.advance === true) { this.step++ }
      }
    },
    wizProgress () {
      this.$emit('progress', this.wizProgress)
    }
  },
  computed: {
    ...mapGetters(['me', 'space', 'spaceSettings', 'profile']),
    spaceId () { return this.space.id },
    spaceReqStatus () { return this.req.space.status },
    settingsReqStatus () { return this.req.settings.status },
    businessNameHint () {
      // profession from global.js mixin
      if (this.professionName && this.spaceTitle) {
        return `e.g.: ${this.spaceTitle}, ${this.professionName}`
      }
      return 'e.g.: Johan Bloem, Biokineticist'
    },
    professionName () { return this.getObjectPath(this.profession, 'name') },
    isMedical () { return this.getObjectPath(this.profession, 'is_medical', true) },
    wizProgress () {
      let steps = 7
      if (this.step > 0) {
        return Math.round(this.step / steps * 100)
      }
      return 0
    }
  }
}
</script>
