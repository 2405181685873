// import Vue from "vue"
import { mapGetters } from "vuex"

export default {
  methods: {
    getObjectPath (object, path, defaultResponse) {
      if (object) {
        let bits = path.split('.')
        for (let key of bits) {
          try {
            if (Object.keys(object).indexOf(key) !== -1) {
              object = object[key]
            } else {
              return defaultResponse
            }
          } catch (e) {
            if (e instanceof TypeError) {
              return defaultResponse
            }
            throw e
          }
        }
        return object
      }
      return defaultResponse
    },
    setfield (model, key, value) {
      this.$store.commit('setField', [model, key, value])
    },
    formatcurrency (currency, number, locale = 'en-ZA') {
      if (!currency) { currency = this.currency }
      if (currency) {
        let formatter = new Intl.NumberFormat(locale, {
          style: 'currency',
          currency: currency
        })
        return formatter.format(number)
      } else {
        return number
      }
    }
  },
  computed: {
    ...mapGetters(['me', 'meId', 'profile', 'space', 'spaceId', 'spaceSettings', 'spaceSettingsId']),
    // USER:
    // PROFILE:
    currency () { return this.getObjectPath(this.settings, 'currency', 'ZAR') },
    profession: {
      get () {
        let pro = this.getObjectPath(this.$store.getters.profile, 'profession', {})
        if(!isNaN(pro) && this.professions) {
          return this.professions.find((prof) => {
            return pro === prof.id
          })
        }
        return pro
      },
      set (value) {
        this.setfield('profile', 'profession', value)
      }
    },
    registrationNumber: {
      get () { return this.$store.getters.profile.registration_number },
      set (value) { this.setfield('profile', 'registration_number', value) }
    },
    profilePicture () {
      if (this.value && this.value.profile && this.value.profile.profile_picture) {
        return null // this.user.profile.profile_picture
      }
      return null
    },
    // SPACE:
    spaceTitle: {
      get () { return this.$store.getters.space.title },
      set (value) { this.setfield('space', 'title', value) }
    },
    // SPACE SETTINGS
    settingsPracticeNumber: {
      get () { return this.getObjectPath(this.$store.getters, 'spaceSettings.practice_number') },
      set (value) {
        this.setfield('settings', 'practice_number', value)
      }
    },
    settingsBankingDetails: {
      get () { return this.getObjectPath(this.$store.getters, 'spaceSettings.banking_details') },
      set (value) {
        this.setfield('settings', 'banking_details', value)
      }
    },
    settingsBillingAddress: {
      get () { return this.getObjectPath(this.$store.getters, 'spaceSettings.billing_address') },
      set (value) {
        this.setfield('settings', 'billing_address', value)
      }
    }
  }
}
