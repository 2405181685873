import Vue from 'vue'
const  TOKEN_KEY = 'kong_appointmentguru_token'

export default {
  namespaced: true,
  state: {
    token: null,
    loginRedirect: '/'
  },
  mutations: {
    setToken(state, token) {
      state.token = token
      if (process.env.NODE_ENV === 'development') {
        Vue.$cookies.set(TOKEN_KEY, token, '24d', null)
      } else {
        Vue.$cookies.set(TOKEN_KEY, token, '24d', null, "appointmentguru.co")
      }
    },
    setLoginRedirect(state, path) {
      state.loginRedirect = path
    }
  },
  actions: {
    initSession ({dispatch}) {
      let token = Vue.$cookies.get(TOKEN_KEY)
      if (token) {
        dispatch('logUserIn', token)
      }
    },
    logUserIn ({commit}, token) {
      commit('setToken', token)
      Vue.$cookies.set(process.env.VUE_APP_COOKIE_NAME, token, '24d', null, 'appointmentguru.co')
      Vue.$cookies.set(process.env.VUE_APP_COOKIE_NAME, token, '24d', null, window.location.hostname)
    },
    logUserOut ({commit}) {
      Vue.$cookies.remove(TOKEN_KEY)
      commit('setToken', null)
    }
  },
  getters: {
    auth (state) {
      return state.token
    },
    isLoggedIn (state) {
      if (state.token && state.token !== 'null') {
        console.log('returning true')
        return true
      } else {
        console.log('returning false')
        return false
      }
    },
    redirectPath (state) {
      if (state.loginRedirect) { return state.loginRedirect }
      return '/'
    }
  }
}
