import Vue from 'vue'
import Vuex from 'vuex'
import Auth from './modules/auth.js'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: null,
  modules: {
    'auth': Auth,
  },
  state: {
    me: {
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      dirty: true
    },
    profile: {
      profession: {},
      profile_picture: '',
      registration_number: '',
      dirty: true
    },
    space: {
      id: null,
      title: '',
      addresses: [],
      products: [],
      dirty: true
    },
    settings: {
      logo_picture: null,
      practice_name: null,
      practice_number: null,
      dirty: true
    },
    idInfo: {
      is_practitioner: null,
      is_active: null,
      deactivation_date: null
    },
    locations: [],
    products: []
  },
  mutations: {
    setMe (state, response) {
      let meFields = ['id', 'first_name', 'last_name', 'email', 'phone_number', 'profile']
      meFields.forEach((field) => {
        if (field in response) {
          Vue.set(state.me, field, response[field])
        }
      })
    },
    setProfile (state, data) {
      let profileFields = ['profile_picture', 'registration_number', 'account_information']
      profileFields.forEach((field) => {
        if (field in data) {
          Vue.set(state.profile, field, data[field])
        }
      })
      if (data.profession && data.profession.id) {
        Vue.set(state.profile, 'profession', data.profession)
      }
    },
    setIdInfo (state, data) {
      Vue.set(state, 'idInfo', data)
    },
    setSpace (state, data) {
      Vue.set(state, 'space', data)
    },
    setSpaceSettings (state, data) {
      if (!data.settings) {
        Vue.set(state, 'settings', data)
        Vue.set(state.space, 'settings', data)
      }
    },
    setLocations (state, locations) {
      state.locations = locations
    },
    setProducts (state, products) {
      state.products = products
    },
    setField (state, modelKeyValue) {
      let mkv = modelKeyValue
      let model = mkv[0]
      let key = mkv[1]
      let val = mkv[2]
      Vue.set(state[model], key, val)
    }
  },
  actions: {
  },
  getters: {
    me (state) { return state.me || {} },
    meId (state) { return state.me.id },
    profile (state) { return state.profile },
    idInfo (state) { return state.idInfo },
    space (state) { return state.space || {} },
    spaceSettings (state) { return state.settings || {} },
    spaceLocations (state) { return state.locations || [] },
    spaceProducts (state) { return state.products || [] },
    spaceId (state, getters) { return getters.space.id },
    spaceSettingsId (state, getters) {
      if (getters.spaceSettings) {
        return getters.spaceSettings.id
      }
      return null
    }
  }
})
