<style >
pre {
  font-size: small;
  text-align: left !important;
}
</style>
<template>

    <v-window v-model="step" vertical >
      <request-status :request='req.profile' />
      <!-- {{payload}} -->
      <v-window-item >
        <!-- <pre v-text="profile" /> -->
        <!-- :value='req.profile.loading' -->
        <v-overlay v-if='req.profile.loading' absolute opacity="0.4" class='text-center' >
          <v-progress-circular color="amber" width="2" indeterminate size=70 /><br/>
          loading your profile
        </v-overlay>
        <center>
          <template v-if='accountInfo.is_new_signup === false' >
            <h1>Welcome back {{me.first_name}}!</h1>

            <template v-if='!isActive' >
              <p class='mt-3 text-h5 font-weight-light'>
                Looks like your free trial has expired :(.
              </p>

              <p class='mt-3 text-h5 font-weight-light'>
                Would you like to upgrade your account?
              </p>

              <v-btn large
                  :href='accountInfo.upgrade_link'
                  color='success'
                  target='_blank'
                  class='mb-4' >Upgrade</v-btn>

              <div>
                <v-btn text small
                  :loading='req.extend.loading'
                  class='mt-5'
                  @click='extend' >
                    Extend my free trial
                </v-btn>
              </div>
            </template>

            <template v-if='isActive' >

              <p class='mt-3 text-h5 font-weight-light'>
                Looks like you already have an active AppointmentGuru account.
                You can still go through the wizard and use it to update your settings.
              </p>
              <div style='width:300px;' >
                <v-btn large block text
                  href='https://app.appointmentguru.co'
                  @click="step++"
                  color='primary'
                  class='mb-4' >Just take me to the app</v-btn>
                <v-btn large block
                  @click="step++"
                  color='primary'
                  class='mb-4' >Start the wizard!</v-btn>
              </div>
            </template>
          </template>
          <template v-else >
            <h1>Welcome {{me.first_name}}!</h1>

            <p class='mt-3 text-h5 font-weight-light'>
              We're excited to get you started with AppointmentGuru<br/>
              First up, let's load your personal details
            </p>

            <v-btn large
              @click="step++"
              color='primary'
              class='mb-4' >Let's go!</v-btn>
          </template>

        </center>

      </v-window-item>
      <v-window-item >
        <!-- {{typedProfession}} | {{profession}} -->
        <v-form >
          <v-card tile class='ma-4 elevation-10' >

            <v-autocomplete filled return-object autofocus persistent-hint
              v-model='profession'
              label='Profession'
              :searchInput.sync='typedProfession'
              :items='autoitems'
              item-value='id' item-text='name' >
            </v-autocomplete>

            <v-card outlined
              v-if='profession && profession.id === 0'
              class='mx-3 pa-2 mb-3 grey lighten-4' >
                <v-card-title class="overline" >
                  {{profession.name}}
                  <v-spacer/>
                  <v-btn @click='createProfession' color="primary" >Create</v-btn>
                </v-card-title>
                <v-card-text >
                  <v-checkbox persistent-hint
                      v-model='profession.is_medical'
                      class='mb-3'
                      label='Is this a medical profession?'
                      hint="For medical professions we'll turn on features related to medical aids etc." />
                </v-card-text>
            </v-card>

            <template v-if="(profession && profession.is_medical) || isMedical" >
              <v-text-field filled persistent-hint autofocus
                v-model='registrationNumber'
                label='Would you like to add a registration number? (optional)'
                hint="e.g.: Your HPCSA number - it can be included on your invoices" />
            </template>
            <v-card-actions >
              <v-spacer/>
              <v-btn
                @click='save()'
                color='primary'
                :loading='req.profile.loading'
                :disabled='!professionIsValid' >
                Next
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-window-item >

      <v-window-item >
        <v-card tile class='ma-4 elevation-10' >
          <v-card-title class='subtitle-1 overline justify-center' >
            Add a profile pic?
          </v-card-title>
          <v-alert v-if='profilePictureUploadError' type='error' >
            There was a problem uploading your profile picture. Please try again, or skip this step
          </v-alert>
          <uploader flat
            v-model='profile.profile_picture'
            filename='profile_picture'
            :upload-path='`/api/v2/practitioner/me/profile/${me.id}/`'
            @input='step++'
            @uploadfailure='profilePictureUploadError=true' />

          <v-card-actions >
            <v-spacer/>
            <v-dialog v-model='showProfileInfo' width="500" >
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-on="on" v-bind="attrs" >Skip this step</v-btn>
              </template>
              <v-card>
                <v-card-title class='subtitle-1 overline' >
                </v-card-title>
                <v-card-text>
                  You can add a profile picture later from settings -> profile
                </v-card-text>
                <v-card-actions >
                  <v-spacer/>
                  <v-btn @click='showProfileInfo=false;step++' text >Got it</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </v-window-item>

      <v-window-item >
        <v-card>
          <v-card-title class='justify-center overline pb-10' >
            Looking good {{fullname}}!
          </v-card-title>
          <v-card-text>
            <profile-card v-model='card' class='mt-5' >
              <template slot="actions" >
                <v-divider />
                <v-card-actions >
                  <v-spacer />
                  <v-btn @click="$emit('done')" color='primary' >Next</v-btn>
                </v-card-actions>
              </template>
            </profile-card>
          </v-card-text>
        </v-card>
      </v-window-item>

      <!-- <pre v-text='me' />
      <pre v-text='profile' /> -->
      <!-- ME:
      <pre v-text='me' />
      <hr/>
      Profile:
      <pre v-text='profile' />
      Store profile:
      <pre v-text='p' /> -->
      <v-dialog v-model="freeTrialExtended" >
        <v-card>
          <v-card-title class='overline' >
            Free trial extended
          </v-card-title>
          <request-status :request='req.extend' />
          <v-card-text>
            <p class='mt-3 text-h5 font-weight-light' v-if='extendReqStatus === 403' >
              It looks like you might have exceeed the number of free trial extensions permitted.
              If you feel like this was a mistake, please contact support@appointmentguru.co
            </p>
            <template v-if='extendReqStatus === 200' >
              <p class='mt-3 text-h5 font-weight-light' >
                You free trial has been extended by {{freeTrialExension}} days.
                If you would like any assistance getting to know about AppointmentGuru,
                please contact support@appointmentguru.co,
                we can help get you set up, give you a product demo or answer any questions you might have.
              </p>
              <p class='mt-3 text-h5 font-weight-light' >
                Close this window to continue setting up your account
              </p>
            </template>
          </v-card-text>

          <v-card-actions >
            <v-spacer/>
            <v-btn text @click='freeTrialExtended=false' >Done</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-window>

</template>
<script>
import ProfileCard from '@/components/ProfileCard.vue'
import Uploader from '@/components/ui/Uploader.vue'

export default {
  name: 'Personal',
  components: {ProfileCard, Uploader},
  data () {
    return {
      step: 0,
      showProfileInfo: false,
      profilePictureUploadError: false,
      freeTrialExtended: false,
      // profession: {},
      registration_number: '',
      typedProfession: '',
      isMedical: false,
      professions: [],
      req: {
        profile: {
          resource: 'ag.practitioner.me.profile'
        },
        profession: {
          resource: 'ag.professions'
        },
        extend: {
          resource: 'ag.practitioners',
          extra: 'actions/extend_free_trial',
          method: 'post'
        }
      }
      // profile: {
      //   profession: {},
      //   registration_number: null,
      //   profile_picture: null
      // }
    }
  },
  mounted () {
    this.getProfessions()
  },
  methods: {
    getProfessions () {
      this.$request('get', this.req.profession)
    },
    save () {
      this.req.profile.data = this.payload
      this.req.profile.resourceId = this.me.id
      this.req.profile.method = 'patch'
      this.$request('save', this.req.profile)
    },
    createProfession () {
      this.req.profession.data = this.profession
      this.$request('save', this.req.profession)
    },
    extend () {
      this.req.extend.resourceId = this.meId
      this.$request('save', this.req.extend)
    }
  },
  watch: {
    professionPrefix () {
      if (this.professionPrefix && this.professionPrefix.length < 4) {
        this.registrationNumber = ''
      }
      if (this.professionPrefix) {
        this.registrationNumber = this.professionPrefix
      }
    },
    profileReqStatus () {
      if (this.profileReqStatus === 200) {
        let data = {}
        if (this.profession && this.profession.id) {
          data['profession'] = this.profession
        }
        if (this.profile.registration_number) {
          data['registration_number'] = this.profile.registration_number
        }
        if (this.profile.profile_picture) {
          data['profile_picture'] = this.profile.profile_picture
        }
        this.$store.commit('setProfile', data)
        this.step++
      }
    },
    professionRequestStatus () {
      if (this.professionRequestStatus === 200) {
        this.professions = this.getObjectPath(this.req.profession, 'result', [])
      }
      if (this.professionRequestStatus === 201) {
        let profession = this.req.profession.result
        this.professions.push(profession)
        this.profession = profession
      }
    },
    wizProgress () {
      this.$emit('progress', this.wizProgress)
    },
    extendReqStatus () {
      if (this.extendReqStatus > 199) {
        this.freeTrialExtended = true
      }
    },
    freeTrialExtended () {
      console.log(this.freeTrialExtended, this.extendReqStatus)
      if (this.freeTrialExtended === false && this.extendReqStatus === 200) {
        this.step++
      }
    }
  },
  computed: {
    customProfession () {
      return {
        id: 0,
        name: this.typedProfession,
        is_medical: this.isMedical
      }
    },
    accountInfo () { return this.getObjectPath(this.profile, 'account_information', {}) },
    freeTrialExension () { return this.getObjectPath(this.req.extend, 'result.days_extended', 0)},
    freeTrialExpired () { return this.getObjectPath(this.accountInfo, 'free_trial.expired') },
    isActive () { return this.getObjectPath(this.accountInfo, 'is_active') },
    card () {
      let data = this.me
      data.profile = this.p
      return data
    },
    fullname () {
      return this.me.first_name + ' ' + this.me.last_name
    },
    professionName () {
      return this.getObjectPath(this.p, 'profession.name')
    },
    professionPrefix () {
      if (this.profession && this.profession.prefix) {
        return this.profession.prefix
      }
      return ''
    },
    professionIsValid () {
      return (this.profession && this.profession.id)
    },
    profileReqStatus () { return this.req.profile.status },
    extendReqStatus () { return this.req.extend.status },
    professionRequestStatus () {
      return this.req.profession.status
    },
    autoitems () {
      let custom = []
      if (this.typedProfession) {
        custom = [this.customProfession]
      }
      return this.professions.concat(custom)
    },
    payload () {
      let data = {}
      if (this.registrationNumber && this.registrationNumber.length > 3) {
        data.registration_number = this.registrationNumber
      }
      let professionId = this.getObjectPath(this.profile, 'profession.id')
      if (professionId) {
        data.profession = professionId
      }
      return data
    },
    wizProgress () {
      let steps = 3
      if (this.step > 0) {
        return Math.round(this.step / steps * 100)
      }
      return 0
    }
  }
}
</script>
