<template>
<v-card>
  <request-status :request='reqs.get' />
  <request-status
    :request='reqs.save'
    message201='Appointment type created' />
  <v-card-title class='subtitle-1 overline justify-center' >
    Add appointment types
  </v-card-title>

  <v-card-text >
    <v-window v-model="step" vertical >
      <v-window-item >
        <v-card>
          <!-- <location-picker v-model="l" /> -->
          <!-- {{locations}} -->
          <v-card-text class='text-h5 font-weight-light my-5 text-center' >
            Appointment types are like templates that make it easy for you to quickly add
            appointments to your diary.<br/>
            They are also used to generate time slots on your online booking form
          </v-card-text>
          <v-card-actions >
            <v-spacer/>
            <v-btn large @click='step++' class='mb-5' color="primary" >
              Got it! Let's add some appointment types
            </v-btn>
            <v-spacer/>
          </v-card-actions>
        </v-card>
      </v-window-item >
      <v-window-item >
        <v-text-field filled autofocus persistent-hint
          v-model='at.title'
          label='What is the name of this appointment type'
          hint='For example, Initial consultation, Follow-up consultation'
          append-icon='mdi-arrow-right'
          @keypress.enter="step++"
          @click:append='step++' />

        <v-card-actions >
          <v-spacer/>
          <v-btn color='primary' :disabled='!at.title' @click='step++' >Next</v-btn>
        </v-card-actions>

      </v-window-item >
      <v-window-item >
        <v-text-field filled autofocus
          type="Number"
          v-model='at.duration'
          label='How long is this appointment type'
          append-icon='mdi-arrow-right'
          @keypress.enter="step++"
          @click:append='step++' />

        <v-card-actions >
          <v-btn flat @click='step--' >Back</v-btn>
          <v-spacer/>
          <v-btn color='primary'
            :disabled='!at.duration || at.duration < 5'
            @click='step++' >Next</v-btn>
        </v-card-actions>

      </v-window-item >
      <v-window-item >
        <v-text-field filled autofocus
          type="Number"
          v-model='at.price'
          label='Default price'
          append-icon='mdi-arrow-right'
          @keypress.enter="step++"
          @click:append='step++' />

        <v-card-actions >
          <v-btn flat @click='step--' >Back</v-btn>
          <v-spacer/>
          <v-btn color='primary'
            :disabled='!at.duration || at.duration < 5'
            @click='step++' >Next</v-btn>
        </v-card-actions>

      </v-window-item >

      <v-window-item >

        <location-picker v-model='at.locations' />

        <v-card-actions >
          <v-btn flat @click='step--' >Back</v-btn>
          <v-spacer/>
          <v-btn text @click='create();$emit("next")'
            :disabled='!at.locations || at.locations.length === 0'
            color='primary' >
            <span v-if='at.id' >Save and next</span>
            <span v-else >Create and next</span>
          </v-btn>

          <v-btn @click='create'
            :disabled='!at.locations || at.locations.length === 0'
            :loading='reqs.save.loading'
            color='primary' >
            <span v-if='at.id' >Save</span>
            <span v-else >Create</span>
          </v-btn>

        </v-card-actions>

      </v-window-item >
    </v-window>
    <!-- Quick add:
    <v-chip x-small label v-for='suggestion in suggestions' :key='suggestion.title' @click='at=suggestion;add()' >
      {{suggestion.title}}
    </v-chip> -->

    <v-card v-if='!initial || numProducts > 1' class='mt-10' >
      <v-overlay absolute :value='loading' >
        <v-progress-circular indeterminate color="amber" width="2" :opacity='0.1' />
      </v-overlay>
      <v-card-title class="overline grey lighten-4" >
        Appointment types
      </v-card-title>
      <v-btn fab absolute top right small color='primary' >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-data-table dense hide-default-footer
        no-data-text="None created yet"
        :items='products'
        :headers='headers'
        @click:row='editRow' >
        <template v-slot:item.delete="{ item }" >
        <!-- <v-icon>mdi-trash</v-icon> -->
          <v-btn x-small icon
            @click.stop='deleteProduct(item)'
            :loading="reqs.del.loading"
            color='error' ><v-icon small >mdi-delete</v-icon></v-btn>
        </template>

        <template slot='item.edit' >
        <!-- <v-icon>mdi-trash</v-icon> -->
          <v-btn x-small icon ><v-icon small >mdi-pencil</v-icon></v-btn>
        </template>
      </v-data-table>
    </v-card>
    <!-- <pre v-text='products' /> -->
  </v-card-text>
  <v-card-actions v-if='step > 0' >
    <v-btn @click='$emit("back")' text >Back</v-btn>
    <v-spacer/>
    <v-btn v-if='!initial || numProducts > 1'
      @click="$emit('next')"
      color='primary'
      :text='numProducts===1' >Done</v-btn>
  </v-card-actions>

  <v-dialog v-model='editRowDialog' >
    <request-status
      :request='reqs.save'
      message201='Appointment type created' />
    <v-card>
      <v-card-title class='subtitle-1 overline' >
        Edit {{editAt.title}}
        <v-spacer/>
        <v-btn @click='editRowDialog=false' icon ><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-text-field filled autofocus
          v-model='editAt.title'
          label='Price' />
          <v-text-field filled autofocus
          type="Number"
          v-model='editAt.duration'
          label='Duration' />
        <v-text-field filled autofocus
          type="Number"
          v-model='editAt.price'
          label='Price' />
        <location-picker v-model='editAt.locations' :attrs="{returnObject: false}" />
      </v-card-text>
      <v-card-title >
        <v-btn text @click='editRowDialog=false' >Cancel</v-btn>
        <v-spacer/>
        <v-btn @click="save" color="primary"
          :loading='reqs.save.loading'
          :disabled='!editIsValid' >Save</v-btn>
      </v-card-title>
    </v-card>
  </v-dialog>
</v-card>
</template>
<script>
import LocationPicker from '@/components/pickers/LocationPicker.vue'
import {mapGetters} from 'vuex'

export default {
  name: 'AppointmentTypes',
  components: {LocationPicker},
  data () {
    return {
      initial: true,
      step: 0,
      gotIt: true,
      editRowDialog: false,
      at: { title: '', duration: 30, price: 400, locations: [] },
      editAt: {},
      headers: [
        { text: 'Title', value: 'title' },
        { text: 'Price', value: 'price' },
        { text: 'Duration', value: 'duration' },
        { text: '', value: 'delete' },
        { text: '', value: 'edit' }
      ],
      reqs: {
        get: { resource: 'ag.spaces.products.v3' },
        save: { resource: 'ag.spaces.products.v3' },
        del: { resource: 'ag.spaces.products.v3'}
      },
      l: [],
      suggestions: [
        {
          title: 'Discovery Vitality Assessment', price: 456, duration: 30
        }
      ]
    }
  },
  mounted () {
    this.get()
  },
  methods: {
    get () {
      this.$request('get', this.reqs.get)
    },
    editRow (item) {
      this.editAt = item
      this.editRowDialog = true
    },
    getNewAt () {
      return { title: '', duration: 30, price: 400, locations: [] }
    },
    create () {
      this.reqs.save.data = this.at
      this.reqs.save.resourceId = null
      if (this.at.id) {
        this.reqs.save.resourceId = this.at.id
      }
      this.reqs.save.data['service'] = this.spaceId
      this.$request('save', this.reqs.save)
      this.initial = false
    },
    save () {
      this.reqs.save.resourceId = this.editAt.id
      this.reqs.save.data = this.editAt
      this.$request('save', this.reqs.save)
      this.initial = false
    },
    deleteProduct (item) {
      this.reqs.del.resourceId = item.id
      this.$request('delete', this.reqs.del)
    }
  },
  watch: {
    numProducts () {
      if (this.numProducts === 1) { this.at = this.products[0] }
      this.$store.commit('setProducts', this.products)
    },
    saveReqStatus () {
      if (this.saveReqStatus === 200 || this.saveReqStatus === 201) {
        this.at = this.getNewAt()
        this.step = 1
        this.get()
        this.initial = false
        this.editRowDialog = false
      }
    },
    delReqStatus () {
      if (this.delReqStatus === 204) {
        this.get()
        this.step = 1
        this.initial = false
      }
    }
  },
  computed: {
    ...mapGetters(['spaceId']),
    products () {
      return this.getObjectPath(this.reqs.get, 'result.results', [])
    },
    numProducts () {
      return this.products.length
    },
    loading () {
      return ((this.reqs.get.loading === true) || (this.reqs.save.loading === true) || (this.reqs.save.loading === true))
    },
    editIsValid () {
      return (this.editAt.title && this.editAt.duration && this.editAt.duration > 5 && this.editAt.price && this.editAt.locations && this.editAt.locations.length > 0)
    },
    getReqStatus () { return this.reqs.get.status },
    saveReqStatus () { return this.reqs.save.status },
    delReqStatus () { return this.reqs.del.status }
  }
}
</script>
