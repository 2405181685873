<template>
<v-card class='grey lighten-4 text-center' >
  <v-avatar class='teal elevation-10 white--text' width='100' height='100' style='margin-top:-40px;' >
    <v-img
      v-if='profilePicture' :src='profilePicture'
      width='100px' height='100px' />
    <span style='font-size:35px;' v-else >{{ initials }}</span>
  </v-avatar>
  <v-card-title class='justify-center text-center overline' >
    {{fullname}}.
    <span v-if='professionName' >{{professionName}}</span>
  </v-card-title>
  <v-card-text >
    <div>{{profile.registration_number}}</div>
    <v-icon small >mdi-cellphone</v-icon> {{value.email}}
    <span v-if='value && value.phone_number' >
      <v-icon class='ml-3' small >mdi-email</v-icon> {{value.phone_number}}
    </span>

    <!-- <v-divider class='mt-3' />
    <center>
      <v-checkbox persistent-hint
        label="Would you like us to make a personal website for you?"
        hint="Based on the information you've entered, we can create and host a personal website for you for free! You will be able to customize it further - we'll drop you an email with the details" />
    </center> -->
  </v-card-text>
  <slot name="actions" >
  </slot>
</v-card>
</template>
<script>
export default {
  name: 'ProfileCard',
  props: {
    value: { type: Object }
  },
  computed: {
    fullname () {
      let fname = this.value.first_name
      let sname = this.value.last_name
      if (sname) { return `${fname} ${sname}` }
      return fname
    },
    professionName () {
      // from global:computed.
      return this.getObjectPath(this.profession, 'name')
    },
    profilePicture () {
      return this.getObjectPath(this.profile, 'profile_picture')
    },
    initials () {
      let ini = ''
      if (this.value.first_name) { ini = this.value.first_name[0] }
      if (this.value.last_name) { ini += this.value.last_name[0] }
      return ini
    }
  }
}
</script>
