<template>
<v-card flat>
  <v-window v-model="step" vertical >
    <v-window-item >
      <v-card>
        <v-card-text class='text-h5 font-weight-light my-5 text-center' >
          You can add one or more locations to your space. <br/>
          A location can be physical or virtual. <br/>
          You can assign your appointment types to your locations and define custom operating hours for each location.
        </v-card-text>
        <v-card-title >
          <v-spacer/>
          <v-btn large @click='step++' class='mb-5' color="primary" >Got it!</v-btn>
          <v-spacer/>
        </v-card-title>
      </v-card>
    </v-window-item >

    <v-window-item>
      <v-card class='ma-sm-2 grey lighten-2' >
        <v-card-text >
          <v-subheader class='overline pl-2' >Find your location in Google Maps</v-subheader>
          <address-picker
            v-model='place'
            api-key='AIzaSyDBLqBJhup-u2afKcqqonWzGC-vv_7YpnY' />
        </v-card-text>
      </v-card>

      <v-card outlined v-if='placeId || edit' >
        <v-card-title v-if='edit' >
          <v-spacer/>
          <v-btn @click='edit=false;selectedLocation={};place={}' icon small ><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text transition="slide-y-transition" >
          <request-status :request="req" />
          <v-text-field filled autofocus persistent-hint
            v-model='selectedLocation.name'
            label='Address name' />

          <v-text-field filled autofocus persistent-hint
            v-model='selectedLocation.address'
            label='Address' />

          <v-row >
            <v-col cols=12 sm=6 >
              <v-text-field filled autofocus persistent-hint
                v-model='selectedLocation.latitude'
                label='Latitude' />
            </v-col>
            <v-col cols=12 sm=6 >
              <v-text-field filled autofocus persistent-hint
                v-model='selectedLocation.longitude'
                label='Longitude' />
            </v-col>
          </v-row>

          <v-card-actions >
            <v-btn
              v-if='selectedLocation.id'
              @click='deleteLocation(selectedLocation)' color='error' >Delete</v-btn>
            <v-spacer/>
            <v-btn @click="save" color="primary" :loading='req.loading' >Save</v-btn>
            <v-btn text @click="save();$emit('next')" color="primary" >Save and next</v-btn>
          </v-card-actions>

        </v-card-text>
      </v-card>

      <v-card class="mt-10 elevation-10 mx-2" >
        <!-- L:{{loading}} | {{req.loading}} | {{delReq.loading}} -->
        <v-overlay absolute :value='loading' >
          <v-progress-circular indeterminate color="amber" width="2" :opacity='0.1' />
        </v-overlay>

        <v-card-title class="overline grey lighten-2 py-0" >Locations</v-card-title>

        <v-btn fab absolute top right small
          color='primary'
          @click='newLocation' >
          <v-icon>mdi-plus</v-icon>
        </v-btn>

        <v-data-table dense hide-default-footer
          no-data-text="None created yet"
          :items='locations'
          :headers='headers'
          @click:row='editRow' >
          <template v-slot:item.delete="{ item }" >
            <!-- <v-icon>mdi-trash</v-icon> -->
            <v-btn x-small icon
              @click.stop='deleteLocation(item)'
              :loading="delReq.loading"
              color='error' ><v-icon>mdi-delete</v-icon></v-btn>
          </template>
        </v-data-table>

      </v-card>

      <!-- <v-btn @click='get' class='mt-4' /> -->
      <v-divider class='mt-5' />
      <v-card-actions >
        <v-btn @click='$emit("back")' text >Back</v-btn>
        <v-spacer/>
        <v-btn @click='$emit("next")' :disabled='numLocations < 1' color="primary" >
          Next
        </v-btn>
      </v-card-actions>
    </v-window-item>
  </v-window>
  <!-- place: <pre v-text="place" /> -->
</v-card>
</template>
<script>
import AddressPicker from '@/components/pickers/AddressPicker.vue'
import {mapGetters} from 'vuex'

export default {
  name: 'Locations',
  components: { AddressPicker },
  data () {
    return {
      getReq: { resource: 'ag.spaces', extra: 'addresses' },
      req: { resource: 'ag.spaces', extra: 'addresses' },
      delReq: { resource: 'ag.addresses' },
      selectedLocation: {},
      edit: false,
      step: 0,
      place: {},
      headers: [
        { text: '', value: 'delete' },
        { text: 'Name', value: 'name' },
        // { text: 'Address', value: 'address' },
        { text: 'Latitude', value: 'latitude' },
        { text: 'Longitude', value: 'longitude' }
      ]
    }
  },
  methods: {
    get () {
      this.getReq.resourceId = this.spaceId
      this.$request('get', this.getReq)
    },
    editRow (row) {
      this.selectedLocation = row
      this.edit = true
    },
    save () {
      this.req.data = this.selectedLocation
      if (this.selectedLocation && this.selectedLocation.name) {
        this.req.data.practitioner = this.meId
        this.req.resourceId = this.spaceId
        this.req.method = 'post'
        if (this.selectedLocation && this.selectedLocation.id) {
          this.req.method = 'patch'
          this.req.extra = 'addresses/' + this.selectedLocation.id
        }
        this.$request('save', this.req)
      }
    },
    deleteLocation (location) {
      if (location && location.id) {
        this.delReq.resourceId = location.id
        this.$request('delete', this.delReq)
      }
    },
    newLocation () {
      this.selectedLocation.id = null
      this.edit = false
    }
  },
  watch: {
    numLocations () {
      if (this.numLocations > 0) {
        this.selectedLocation = this.locations[0]
        this.$store.commit('setLocations', this.locations)
      }
    },
    spaceId: {
      immediate: true,
      handler () {
        if (this.spaceId) {
          this.get()
        }
      }
    },
    placeId () {
      if (this.placeId) {
        // this.selectedLocation.id = null
        this.selectedLocation.name = this.place.name
        this.selectedLocation.address = this.place.formatted_address
        this.selectedLocation.latitude = this.getObjectPath(this.place, 'geometry.location.lat')()
        this.selectedLocation.longitude = this.getObjectPath(this.place, 'geometry.location.lng')()
        this.save()
      }
    },
    reqStatus () {
      if (this.reqStatus === 201) { this.get() }
    },
    delReqStatus () {
      if (this.delReqStatus === 204) { this.get() }
    }
  },
  computed: {
    ...mapGetters(['spaceId']),
    locations () {
      return this.getObjectPath(this.getReq, 'result.results', [])
    },
    getReqStatus () { return this.getReq.status },
    delReqStatus () { return this.delReq.status },
    reqStatus () { return this.req.status },
    loading () { return (this.req.loading === true) || (this.delReq.loading === true) || (this.getReq.loading === true) },
    placeId () { return this.getObjectPath(this.place, 'place_id') },
    numLocations () { return this.locations.length }
  }
}
</script>
