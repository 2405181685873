<template>
<v-card :flat='flat' >
  <v-toolbar dense flat >
    <v-btn icon ><v-icon>mdi-reload</v-icon></v-btn>
    <v-toolbar-title>{{title}}</v-toolbar-title>
  </v-toolbar>

  <v-card-text class='text-center' >
    <center>
      <v-avatar v-if='value' size='200' class='mb-3' :tile='avatarTile' >
        <v-img :src='value' height='200' width=200 />
      </v-avatar>
      <div class='drop-area' :class="classList"
        @dragenter.prevent='setHover(true)'
        @dragover.prevent='setHover(true)'
        @dragleave.prevent='setHover(false)'
        @drop.prevent='drop' >
        <form class="my-form">
          <!-- <h5><v-icon v-if='uploadSuccess' color='success'>check_circle</v-icon> </h5> -->
          <p>{{subtitle}}</p>
          <input
              type="file" class='fileElem'
              name="fileElem" :id="id"
              multiple accept="image/*"
              @change.prevent="filesSelected" >
          <label v-if='!uploading' class="button block" :for="id">Select some files</label>
          <label v-if='!uploading' >or drop some files here</label>
          <div class='gallery' >
            <slot name='gallery' :images='images' >
              <div>
                <img class='img' v-for='(img, index) in images' :key='index' :src='img' />
              </div>
            </slot>
            <v-btn
              @click="uploadFile"
              :disabled='!file'
              :loading="uploading"
              color='primary' class='mt-3' block ><v-icon>mdi-file-upload</v-icon> Upload</v-btn>
            <!-- <v-progress-linear v-if='uploading' :indeterminate="true"></v-progress-linear> -->
          </div>
        </form>
      </div>
    </center>
  </v-card-text>
</v-card>

</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'Uploader',
  props: {
    title: { type: String, default: 'Drag and Drop files here' },
    subtitle: { type: String },
    filename: { type: String, default: 'file' },
    uploadPath: { type: String, required: true },
    value: { type: String, required: false, description: 'The actual image url' },
    flat: { type: Boolean, default: false },
    avatarTile: { type: Boolean, default: false },
    uploadOnDrop: { type: Boolean, default: true }
  },
  data () {
    return {
      hovering: false,
      id: Math.random().toString().split('.')[1],
      file: null,
      images: [],
      uploading: false,
      uploadSuccess: false
    }
  },
  methods: {
    l (name) {
      console.log(name)
    },
    setHover (onOff) {
      this.hovering = onOff
    },
    previewFile (file) {
      let reader = new FileReader()
      let vm = this
      reader.readAsDataURL(file)
      vm.images = []
      vm.file = file
      reader.onloadend = function () {
        vm.images.push(reader.result)
        // let img = document.createElement('img')
        // img.src = reader.result
        // document.getElementById('gallery').appendChild(img)
      }
    },
    drop (e) {
      let dt = e.dataTransfer
      let files = dt.files
      this.handleFiles(files)
      this.hovering = false
    },
    filesSelected (e) {
      this.handleFiles(e.currentTarget.files)
    },
    handleFiles (files) {
      files = [...files]
      files.forEach(this.previewFile)
      // files.forEach(this.uploadFile)
      if (this.uploadOnDrop) {
        let vm = this
        window.setTimeout(() => { vm.uploadFile() }, 200)
      }
    },
    uploadFile () {
      let file = this.file
      let formData = new FormData()
      let vm = this
      formData.append(this.filename, file)
      let base = process.env.VUE_APP_APPOINTMENTGURU_API_ROOT
      // let url = `${base}/api/v2/practitioner/me/profile/${this.me.id}/`
      // let url = 'http://localhost:8000/image/'
      let url = `${base}${this.uploadPath}`
      vm.uploading = true

      let config = {
        headers: {
          'authorization': 'Bearer ' + this.auth
        }
      }
      axios.patch(url, formData, config)
      .then((data) => {
        vm.$emit('uploadsuccess', data)
        let dataPath = `data.${this.filename}`
        let newlogo = this.getObjectPath(data, dataPath)
        if (newlogo) {
          vm.$emit('url', newlogo)
          vm.$emit('input', newlogo)
        }
        vm.uploading = false
        vm.uploadSuccess = true
      })
      .catch(() => {
        vm.$emit('uploadfailure')
        vm.uploading = false
        vm.uploadSuccess = false
      })
    }
  },
  computed: {
    ...mapGetters('auth', ['auth']),
    classList () {
      if (this.hovering) { return ['highlight'] }
      return []
    },
    me () {
      return this.$store.getters.me
    }
  }
}
</script>

<style scoped>
.drop-area {
  border: 2px dashed #ccc;
  width: 100%;
  font-family: sans-serif;
  margin: auto;
  padding: 20px;
}
.drop-area.highlight {
  border-color: purple;
}
.block { width: 100%; }
p {
  margin-top: 0;
}
.my-form {
  margin-bottom: 10px;
}
.gallery {
  margin-top: 10px;
}
.gallery img {
  max-width: 150px;
}
.gallery .img {
  width: 150px;
  margin-bottom: 10px;
  margin-right: 10px;
  vertical-align: middle;
}
.button {
  display: inline-block;
  padding: 10px;
  background: #ccc;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.button:hover {
  background: #ddd;
}
.fileElem {
  display: none;
}
</style>
