<template>
<v-form @submit.prevent='save' >
  <request-status :request='requests.identify' />
  <v-overlay v-if='(registering || loggingIn)' >
    <v-progress-circular color="amber" class='mb-3' :size='70' width='2' indeterminate /><br/>
    <div v-if='registering' >Creating your account</div>
    <div v-if='loggingIn' >Logging in</div>
  </v-overlay>
  <v-card>

    <v-card-title class='subtitle-1 overline' >
    </v-card-title>
    <v-card-text>
      <v-form @submit.prevent='identify' v-model='emailIsValid' >
        <v-card :class="{'elevation-10': next==='start'}" >
          <v-card-title class='overline py-0 grey darken-4 white--text' >Let's get started</v-card-title>
          <v-text-field filled persistent-hint
            v-model='email'
            label='Your work email'
            append-icon='mdi-arrow-right'
            hint='Enter a valid email address and press enter'
            :rules='rules.email'
            :loading='requests.identify.loading'
            @keypress.enter.prevent='identify'
            @input='requests.identify.result.type="start"'
            @click:append='identify' />

            <v-card-actions
              v-if='emailIsValid && requests.identify.result.type==="start"'
              transition="slide-y-transition" >
              <v-spacer/>
              <v-btn tile
                @click='identify'
                :loading='requests.identify.loading'
                color='primary' >Next</v-btn>
            </v-card-actions>
        </v-card>

      </v-form>

      <template v-if='next==="auth-register"' >
        <request-status :request='requests.register' />
        <v-subheader class='pl-1 text-h5 justify-center my-3' >
          Let's create an account</v-subheader>
        <v-form @submit.stop='identify' v-model='registrationIsValid' >
          <v-text-field filled autofocus persistent-hint
            v-model='fullname'
            label='Your full name'
            hint='Your name and surname'
            :rules='rules.fullname' />

          <v-text-field filled persistent-hint
            v-model='phone'
            label='Your phone number'
            hint='Your phone number starting with the country code. e.g.: +27831111111' />

          <v-text-field filled
            v-model='password'
            label='Choose a password'
            :type="passwordField.type"
            :append-icon="passwordField.icon"
            @click:append='showPassword=!showPassword'
            @keypress.enter="signup" />
        </v-form>
        <v-card-actions v-if='isValid' >
          <v-spacer />
          <v-btn @click="signup" large color="primary" >Create account</v-btn>
        </v-card-actions>
      </template>

      <template v-if='next==="token-auth"' >

        <v-card-text >
        <v-subheader class='text-h5 justify-center text-center my-5 font-weight-light' >
          Looks like you already have an account.
          <br/>Please login
        </v-subheader>
        </v-card-text>

        <request-status
          :request='requests.login'
          message401='true' >
          <template slot='message-401' >
            Authentication failed. Invalid username or password.
            <v-btn small outlined class='mt-2' @click='showRecover=true' >Click here to recover your account</v-btn>
          </template>
        </request-status>

        <v-text-field filled
            v-model='password'
            label='Password'
            :type="passwordField.type"
            :append-icon="passwordField.icon"
            @click:append='showPassword=!showPassword'
            @keypress.enter="login" />
        <v-card-actions v-if='isValid' class='px-0' >
          <v-btn @click='showRecover=true' text >Recover my account</v-btn>
          <v-spacer />
          <v-btn @click="login" large color="primary" class="mr-2" >Login</v-btn>
        </v-card-actions>
      </template>
    </v-card-text>
    <!-- N:{{next}} | {{loginStatus}}
    <v-btn @click="getClientInfo" ></v-btn>
    <pre>{{clientInfo}}</pre> -->
    <!-- <v-btn @click="getRandom" >Random user</v-btn> -->
    <!-- <v-btn @click="password='testtest';login()" >Login</v-btn> -->
  </v-card>
  <v-dialog v-model='showRecover' width='570' >
    <v-card>
      <v-card-title class='grey lighten-4 overline py-0' >
        Recover your account
        <v-spacer />
        <v-btn small icon @click='showRecover=false' >
          <v-icon small >close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <recover
        :email='email'
        :with-otp='recoveryOTP'
        @loggedin='getMe'
        @done='showRecover=false;$emit("done")' />
    </v-card>
  </v-dialog>
</v-form>
</template>
<script>
import axios from 'axios'
import Recover from './Recover.vue'

export default {
  name: 'SignUp',
  components: {Recover},
  data () {
    return {
      emailIsValid: false,
      showPassword: false,
      showRecover: false,
      recoveryOTP: null,
      registrationIsValid: false,
      state: null,
      email: null,
      fullname: null,
      phone: null,
      password: null,
      rules: {
        fullname: [
          value => !!value || 'Required.',
          value => value && value.indexOf(' ') !== -1 || 'don\'t forget your surname'
        ],
        email: [
          value => !!value || 'Required.',
          value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'enter a valid email address'
          }
        ],
        phone: []
      },
      requests: {
        identify: {
          resource: 'ag.auth',
          extra: 'identify',
          method: 'post',
          result: {
            type: 'start'
          }
        },
        register: {
          resource: 'ag.auth',
          extra: 'register/practitioner',
          method: 'post'
        },
        login: {
          resource: 'auth.login',
          method: 'post'
        },
        me: {
          resource: 'ag.practitioner.me'
        }
      },
      clientInfo: {
        id: null,
        data: null
      }
    }
  },
  mounted () {
    if (this.$route.query.email) {
      this.email = this.$route.query.email
    }
    if (!this.email && window.location.search && window.location.search.indexOf('email=') > -1) {
      let bits = window.location.search.split('email=')
      if (bits.length > 1) {
        let email = bits[1]
        if (email) {
          this.email = decodeURIComponent(email)
        }
      }
    }
    if (this.email) {
      this.identify()
    }
  },
  watch: {
    registerStatus () {
      if (this.registerStatus === 200) {
        this.login()
      }
    },
    idStatus () {
      if (this.idStatus === 200) {
        let data = this.getObjectPath(this.requests.identify, 'result.user')
        this.$store.commit('setIdInfo', data)
      }
    },
    loginStatus () {
      if (this.loginStatus === 200) {
        let token = this.requests.login.result.access_token
        this.$store.dispatch('auth/logUserIn', token)
        this.loggedIn()
      }
    },
    meStatus () {
      if (this.meStatus === 200) {
        let user = this.requests.me.result.results[0]
        this.$store.commit('setMe', user)
        if (user.profile) {
          this.$store.commit('setProfile', user.profile)
        }
      }
    },
    next () {
      if (this.next === 'auth-otp') {
        this.showRecover = true
        this.recoveryOTP = true
      }
    }
  },
  methods: {
    checkEmail () {
      this.state = "signup"
    },
    identify () {
      if (this.emailIsValid) {
        this.requests.identify.params = {
          email: this.email,
          practitioner: true
        }
        this.$request('save', this.requests.identify)
      } else {
        window.alert('Please enter a valid email address')
      }
    },
    signup () {
      let user = {
        full_name: this.fullname,
        email: this.email,
        password1: this.password,
        password2: this.password,
        is_practitioner: true
      }
      if (this.phone) {
        user.phone_number = this.phone
      }
      this.requests.register.data = user
      this.$request('save', this.requests.register)
      // this.$emit('input', user)
      // this.$emit('signup', user)
    },
    login () {
      this.requests.login.data = {
        client_id: "94A049E4-5D1F-4D78-B090-0309C68BA2DE",
        client_secret: "C455E955-767F-418C-B69B-A0C7D10D44DD",
        password: this.password,
        username: this.email
      }
      this.$request('save', this.requests.login)
    },
    loggedIn () {
      this.getMe()
      this.$emit('done')
    },
    getClientInfo () {
      let vm = this
      axios.get('https://api.ipify.org?format=json')
        .then((res) => {
          let ip = res.data.ip
          vm.clientInfo.ip = res.data.ip
          axios.get('https://api.clearip.io/ip/' + ip + '/json?apikey=yc757b5c92afdf1529102430eda36316')
            .then((res) => {
              vm.clientInfo.data = res.data
            })
        })
    },
    getMe () {
      this.$request('get', this.requests.me)
    },
    async getRandom () {
      let res = await fetch('https://randomuser.me/api/')
      let json = await res.json()
      this.fullname = json.results[0].name.first + ' ' + json.results[0].name.last
      this.email = `tech+${json.results[0].name.first.toLowerCase()}@appointmentguru.co`
    }
  },
  computed: {
    isValid () {
      return this.password && this.password.length > 1
    },
    registerStatus () { return this.requests.register.status },
    idStatus () { return this.requests.identify.status },
    registering () { return this.requests.register.loading },
    loginStatus () { return this.requests.login.status },
    loggingIn () { return this.requests.login.loading },
    meStatus () { return this.requests.me.status },
    next () { return this.requests.identify.result.type },
    passwordField () {
      if (this.showPassword) {
        return { type: 'text', 'icon': 'mdi-eye-off' }
      } else {
        return { type: 'password', 'icon': 'mdi-eye' }
      }
    }
  }
}
</script>
